// font
@font-face {
  font-family: "NotoSansRegular";
  src: url(../assets/fonts/NotoSans-Regular.ttf);
}

@font-face {
  font-family: "NotoSansBold";
  src: url(../assets/fonts/NotoSans-BoldItalic.ttf);
}

@font-face {
  font-family: "Avenir";
  src: url(../assets/fonts/Avenir-Medium-09.ttf);
}

@font-face {
  font-family: "TypoRound";
  src: url(../assets/fonts/Typo_Round_Regular_Demo.otf);
}
@font-face {
  font-family: "Avenir-Black";
  src: url(../assets/fonts/Avenir-Black-03.ttf);
}
@font-face {
  font-family: "Quicksand";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
}
@font-face {
  font-family: "Quicksand-Bold";
  src: url(../assets/fonts/Quicksand-Bold.ttf);
}
@font-face {
  font-family: "Manrope";
  src: url(../assets/fonts/Manrope-Regular.ttf);
}
@font-face {
  font-family: "ManropeBold";
  src: url(../assets/fonts/Manrope-Bold.ttf);
}

body {
  font-family: Avenir;
  background-color: #f6f6f6 !important;
}

// auth
.auth-screen {
  width: 50%;
  height: 100%;
  position: fixed;
  background-color: #231f20 !important;
}

.auth-logo-group {
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 30px;
}

.auth-logo {
  width: 70px;
}

.line {
  border: solid 3px #ffde17;
}

.qoin-admin {
  margin-bottom: 20px;
  margin-left: 20px;
  font-family: TypoRound;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.image-auth-screen {
  padding-top: 50px;
  height: 65%;
  width: 100%;
}

.main-auth {
  float: right;
  width: 70%;
  padding-top: 136px;
  padding-bottom: 20px;
  padding-left: 30%;
  padding-right: 10%;
}

.qoin-sidebar {
  display: flex;
  justify-items: center;
}

// table
.table td {
  border-top: none !important;
}

table {
  table-layout: fixed;
}

table tr td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table thead td {
  font-family: ManropeBold;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
  background-color: #ffe88c;
}

.table tbody td {
  font-family: ManropeBold;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #231f20;
  vertical-align: middle;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}
tr:nth-child(even) {
  background-color: #f8f8f8;
}

.show-limit {
  padding: 0 8px;
  border-right: 1px solid #979797;
  cursor: pointer;
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
}

.show-limit-title {
  font-family: Avenir;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.show-limit.active {
  color: #fbb12f;
}

.show-limit:first-child {
  padding-left: 0;
}

.show-limit:last-child {
  border-right: none;
}

.table-display {
  margin-top: 5px;
  width: 140px;
  height: 19px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0d0d0;
}

.table-page-changer {
  font-family: Avenir;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.paginate-number {
  font-weight: 900;
  color: #191a1f;
}

.table-footer-page {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.paginate-button {
  background-image: url("../assets/img/pagination-button.svg");
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border: none;
}

.paginate-button:disabled {
  opacity: 0.5;
}

.paginate-button:first-child {
  transform: rotate(90deg);
}

.paginate-button:last-child {
  transform: rotate(-90deg);
}

.page-title {
  font-family: Avenir;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 30px;
  color: #231f20;
}

.card-styles-1 {
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.card-styles-2 {
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
}

.card-styles-3 {
  border-radius: 10px;
  padding: 0 0 20px 0;
  background-color: #ffffff;
}

.button-filter {
  width: 120px;
  height: 50px;
  margin-top: 12px;
  border-radius: 5px;
  color: #cacccf;
  border: solid 3px #f2f2f2;
  background-color: #f2f2f2;
}

.button-filter:focus {
  color: #ffffff;
  border: solid 3px #fbb12f;
  background-color: #fbb12f;
}

.button-view {
  height: 32px;
  // margin: 21px 0 16px;
  // padding: 5px 12px 5px 13px;
  border-radius: 4px;
  background-color: #231f20;
  color: #ffcc00;
  border: none;
  width: 60px;
}

.button-delete {
  width: 73px;
  height: 32px;
  margin: 0 0 0 8px;
  padding: 5px 12px;
  opacity: 0.5;
  border: none;
  border-radius: 3px;
  color: #f2f2f2;
  background-color: #fa6400;
}

.line-filter {
  width: 1px;
  height: 50px;
  // margin: 12px 10px 0 22px;
  margin-left: 36px;
  border: solid 2px #f2f2f2;
}

.value-dashboard {
  font-family: Avenir;
  font-size: 21px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  color: #231f20;
}

.label-text {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.sign-in-text {
  font-family: Avenir;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
}

.button-login {
  font-size: 24px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}

.text-qoin {
  color: #231f20;
}

.login-button {
  width: 302px;
  height: 49px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
  color: #231f20;
  border: none;
  border-radius: 10px;
  background-color: #ffde17;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-container {
  height: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
}

.placeholder-report {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #cacccf;
}

.text-version {
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
}

i {
  cursor: pointer;
}

.error-text {
  font-size: 12px;
  color: #f44336;
  display: none;

  &.active {
    display: block;
  }
}

.css-1bggegk .PrivateDateRangePickerToolbar-penIcon {
  position: relative;
  top: 4px;
  display: none;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: #191a1f;
}
.MuiOutlinedInput-root .Mui-disabled {
  -webkit-text-fill-color: #191a1f;
}
.MuiFormHelperText-root {
  margin-top: 5 !important;
  margin-left: 0 !important;
}
@media only screen and (max-width: 1024px) {
  .auth-screen {
    // z-index: 1;
    height: 30%;
    width: 100%;
    position: absolute;
  }

  .auth-logo-group {
    display: flex;
    align-items: center;
    margin: 10px 0px 0px 20px;
  }

  .auth-logo {
    width: 30px;
  }

  .line {
    border: solid 1px #ffde17;
  }

  .image-auth-screen {
    margin-top: 5px;
    height: 55%;
    width: 100%;
  }

  .main-auth {
    width: 100%;
    padding-top: 270px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
  }

  // .page-title {
  //   padding: 10px 20px 0;
  // }

  // .input-form {
  //   padding: 0;
  //   width: 100% !important;
  // }

  .login-button {
    width: 45% !important;
  }

  // .back-button {
  //   width: 45% !important;
  // }

  // .button-next {
  //   margin: 0;
  // }

  // .text-add {
  //   display: none;
  // }

  // .mobile-distance {
  //   margin-top: 10px;
  // }
}

//css custom pagination
.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: #231f20 !important;
  color: #ffcc00 !important;
}

//css custom pagination

.MuiDialog-paperWidthSm {
  left: 125px !important;
  max-width: 900px !important;
  margin: auto !important;
}

.MuiDialog-paperWidthSm::-webkit-scrollbar {
  width: 10px;
}
.MuiDialog-paperWidthSm::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.MuiDialog-paperWidthSm::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.MuiDialog-paperWidthSm::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiPaper-root.MuiCard-root {
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.25);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
@media screen and (min-width: 1120px) {
  .MuiDialog-paperWidthMd {
    left: 125px !important;
  }
}

@media screen and (max-width: 1120px) {
  .MuiDialog-paperWidthSm {
    left: 0 !important;
    margin: auto !important;
    max-width: 900px !important;
  }
}
button:focus {
  outline: 0;
  outline: 0;
}

.MuiDialog-paperWidthXs {
  left: 0 !important;
}

///custom-css-date-range-materialUI
.MuiButtonBase-root.Mui-selected {
  background-color: #ffcc00 !important;
}
.MuiDateRangePickerDay-dayInsideRangeInterval {
  background-color: #fef2dd !important;
}
.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: #fef2dd !important;
}
.MuiButtonBase-root.MuiButton-root {
  color: #ffcc00 !important;
}
///custom-css-date-range-materialUI
