// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

@import "blockui";

.container__page {
  // background-color: #fff;
  .container__page-title {
    font-family: ManropeBold;
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #231f20;
  }
  .container__body {
    padding: 32px;
    background-color: #fff;
  }
  .container__table {
    margin-top: 32px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    .container__table-header {
      .container__table-title {
        // height: 70px;
        font-family: Manrope;
        font-size: 21px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #231f20;
        border-bottom: solid 2px #f7f7f7;
        padding: 14px 26px;
      }
      .container__table-submenu {
        // margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 14px 26px;
        align-items: center;
      }
    }
    .container__table-body {
      // overflow: scroll;
      overflow-y: auto;
      // max-height: 600px;
      table {
        min-width: 100%;
        width: auto;
        // table-layout: fixed;
        border-spacing: 0;
        overflow: scroll;
        // width: 160px;

        thead {
          tr {
            height: 48px;

            td {
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              font-family: "ManropeBold";
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #231f20;
              background-color: #ffe88c;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              // padding-left: 10px;
              padding: 0 12px;
              // width: 200px;
              min-width: 120px;
            }
            td:nth-child(1) {
              width: 80px;
              min-width: 80px !important;
              text-align: center;
            }
            th:nth-child(1) {
              width: 80px;
              min-width: 80px !important;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            height: 48px;
            td {
              font-family: "Manrope";
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.29px;
              color: #231f20;
              vertical-align: middle;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              // padding-left: 10px;
              padding: 12px;
            }
            td:nth-child(1) {
              width: 80px;
              text-align: center;
            }
          }
        }
      }

      tr:nth-child(odd) {
        background-color: #ffffff;
      }

      tr:nth-child(even) {
        background-color: #f8f8f8;
      }
      .button-details {
        width: 78px;
        height: 32px;
        border-radius: 4px;
        background-color: #231f20;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fc0;
      }
    }
    .pagination {
      display: flex;
      margin: 20px 0;
      align-items: center;
      .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
        background-color: #231f20 !important;
        color: #ffcc00 !important;
      }
    }
  }
}

.search-trx {
  .MuiOutlinedInput-input {
    height: 15px;
  }
  .MuiOutlinedInput-root {
    height: 50px;
  }
}

.badge-nonactive {
  color: #ff0404;
  background-color: rgba(255, 3, 0, 0.15);
}

.badge-active {
  color: #05b41d;
  background-color: rgba(68, 255, 0, 0.15);
}

.selectionTotalData {
  .MuiOutlinedInput-root {
    height: 25px;
    width: 70px;
  }
}

@media only screen and (max-width: 800px) {
  .container__page {
    .container__table {
      .container__table-header {
        .container__table-submenu {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }
}
